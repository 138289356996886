<template>
  <span>
    <span
      v-for="(letter, index) in formattedWord"
      :key="index"
      :class="letter.classes"
      :style="{ '--animation-delay': `${letter.animationDelay}s` }"
      v-html="letter.content"
    ></span>
  </span>
</template>

<script>
export default {
  name: 'AnimatedWord',
  props: {
    word: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      animate: true
    }
  },
  computed: {
    formattedWord() {
      const letters = this.word.split('')
      const delayRange = [0.1, 10]
      return letters.map((letter) => ({
        content: letter === ' ' ? '&nbsp;' : letter,
        classes: {
          'animated-letter': letter !== ' ',
          'whitespace': letter === ' ',
          'animated': this.animate && letter !== ' ',
        },
        animationDelay: delayRange[0] + Math.random() * (delayRange[1] - delayRange[0])
      }))
    }
  }
}
</script>

<style scoped>
.animated-letter, .whitespace {
  display: inline-block;
}

.animated {
  animation: bounce-and-wobble 10s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

@keyframes bounce-and-wobble {
  0% {
    transform: translateY(0) rotate(0);
  }
  2% {
    transform: translateY(-0.1em) rotate(5deg);
  }
  5% {
    transform: translateY(0.1em) rotate(-5deg);
  }
  7% {
    transform: translateY(0em) rotate(3deg);
  }
  10% {
    transform: translateY(0) rotate(0);
  }
}
.animated-letter.animated {
  animation-delay: var(--animation-delay);
}
</style>
