<template>
  <div class="flex-grow-1" style="overflow: auto;width: 100%; height: auto;">
    <div
      style="text-align:center;width:60%;max-width: fit-content;margin-left: auto;margin-right: auto;margin-bottom: 15px;"
    >
      <img
        v-if="userData.lgo"
        :src="userData.lgo"
        style="max-width:100px;margin: auto 0;border-radius:10px;"
        class="desktopOnly justify-content-center"
      >
    </div>
    <FlipClockCountdown
      v-if="nextDrawDate"
      v-show="show"
      class="mb-2"
    />
    <PopupMessage
      v-if="userData.cis"
      :popup-message="popupMessage"
      @show="$emit('show')"
    />
    <ul class="list-unstyled pt-sm-3 m-0" style="width:100%;height:fit-content;overflow:auto; border-radius: 5px;">
      <li
        v-for="(item, i) in menuItems"
        v-show="item.boolean && !item.blockUserRoles.includes(userData.rle) && item.show"
        :key="i"
        :class="{'navbarMenuItemAlt' : item.text === 'My Leaderboards', 'navbarMenuItem' : item.text !== 'My Leaderboards', 'selected': $route.path.includes(item.route), 'dnone' : item.text === 'My Leaderboards' && userData.lbl.length === 0}"
      >
        <div
          v-if="item.text === 'My Leaderboards' && userData.lbl.length > 0"
          class="mdbvue-sidenav__item d-flex align-items-center"
        >
          <div class="navbarMenuItemText">
            <mdb-icon
              :icon="item.icon"
              class="mr-3"
            />
            {{ item.text }}
            <div
              style="margin-top: 10px;margin-left: 32px;"
            >
              <li
                v-for="(lb, j) in userData.lbl"
                :key="j"
                class="navbarMenuItem"
                style="margin-left:0px;font-size:smaller;cursor: pointer;"
                @click="goToLeaderboard(lb.Url)"
              >
                <i class="fa fa-shield-alt" style="margin-right: 10px;" /> {{ lb.Club }}
              </li>
            </div>
          </div>
        </div>
        <a
          v-else
          class="mdbvue-sidenav__item d-flex align-items-center"
          @click="goToRoute(item.route)"
        >
          <mdb-icon
            style="width: 25px;"
            :icon="item.icon"
            class="mr-3"
          />
          <div
            class="navbarMenuItemText"
          >
            {{ item.text }}
          </div>
        </a>
      </li>
      <li
        v-if="userData.aut"
        class="navbarMenuItem mb-4"
        @click="logout()"
      >
        <a class="mdbvue-sidenav__item d-flex align-items-center">
          <mdb-icon
            style="width: 25px;"
            icon="sign-out-alt"
            class="mr-3"
          />
          <div class="navbarMenuItemText">Logout</div>
        </a>
      </li>
    </ul>
    <div style="font-size:10px;text-align:center;height: fit-content; color: var(--pr-color); width: 100%;margin-bottom: 150px;">
      <div
        class="mb-2 text-wrap"
        style="padding:10px;"
      >
        grassrootslottery.co.uk is brought to you by Club Supporter Ltd and is licensed and regulated by the UK Gambling Commission (Licence 000-053170-R-330534-001)
      </div>
      <div>Club Supporter Ltd, 76 Front Street, Prudhoe</div>
      <div>NE42 5PU, United Kingdom</div>
      <div class="my-2">
        Grassroots Lottery Version 0.0.4
      </div>
      <img src="../../assets/gc-logo.png" style="width: 100%;max-width:310px;margin-top: 0px;display:initial;">
      <div style="padding:10px;color:white;">
        powered by Cornerways Digital &copy;{{ new Date().getFullYear() }}
      </div>
      <div style="margin: auto;width: 170px;margin-top: 10px;padding-bottom: 50px !important;">
        <div
          class="footer-circle"
          @click="social(1)"
        >
          <i
            class="fa fa-envelope"
            style="font-size: 1.3rem;color:white;margin-top: 2px;"
            title="Need help? Contact us here by email?"
          />
        </div>
        <div
          class="footer-circle"
          style="margin-left: 10px;"
          @click="social(2)"
        >
          <img
            src="@/assets/x.png"
            title="Follow us on 'X'" 
            size="20"
            style="width:25px;margin-top: 0px;margin:auto;"
          >
        </div>
        <div
          class="footer-circle"
          style="margin-left: 10px;color:white;"
          title="Visit our main website: www.grassrootlottery.co.uk"
          @click="social(3)"
        >
          www
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FlipClockCountdown from '@/components/countdown/flipCountdown.vue'
import PopupMessage from '../layout/popupMessage.vue'
import { WEB_URL } from '@/common/config'

export default {
  name: 'SideMenu',
  components: { FlipClockCountdown, PopupMessage },
  data () {
    return {
      show: true,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      menuItems: [
        { text: 'Play', route: '/play?page=1', boolean: true, icon: 'play', blockUserRoles: [], show: true },
        { text: 'My Heroes', route: '/myheroes', boolean: true, icon: 'star', blockUserRoles: [], show: true },
        { text: 'Results', route: '/results', boolean: true, icon: 'clipboard', blockUserRoles: [], show: true },
        { text: 'My Account', route: '/myaccount', boolean: true, icon: 'user', blockUserRoles: [] , show: true },
        { text: 'My Leaderboards', route: '/leaderboard', boolean: true, icon: 'table', blockUserRoles: [], show: true },
        { text: 'My Club', route: '/myclub', boolean: true, icon: 'medal', blockUserRoles: ['pla'], show: true },
        { text: 'Payment Details', route: '/paymentdetails', boolean: true, icon: 'credit-card', blockUserRoles: [], show: true },
        { text: 'Transactions', route: '/transactions', boolean: true, icon: 'money-bill', blockUserRoles: [], show: true },
        { text: 'Self-Exclusion', route: '/selfexclusion', boolean: true, icon: 'user-slash', blockUserRoles: [], show: true },
        { text: 'Admin', route: '/adminops', boolean: true, icon: 'cog', blockUserRoles: ['pla', 'adm'], show: true }
      ],
      popupMessage: { cssBackgroundColour: 'red', FAiconCode: 'fa fa-exclamation', popupText: 'Payment Alert', buttonText: 'RESOLVE', buttonURL: '/paymentdetails'  }
    }
  },
  computed: {
    ...mapGetters([
      'userData', 'nextDrawDate'
    ])
  },
  created () {
    this.iOS = ['iPhone'].indexOf(navigator.platform) >= 0
    if (this.iOS) {
      if (this.windowWidth < 376) {
        this.show = false
      }
    }
  },
  methods: {
    social(type) {
      if (type === 1) {
        location.href = 'mailto:support@clubsupporter.co.uk?subject=Grassroots Lottery Support'
      }
      if (type === 2) {
        window.open('https://x.com/GrassrootsLotto', '_blank')
      }
      if (type === 3) {
        window.open('https://www.grassrootslottery.co.uk', '_blank')
      }
    },
    goToLeaderboard (route) {
      this.$store.commit('storeClubUrl', route)
      this.$router.push({ redirect: window.location.href = WEB_URL + '/' + route })
    },
    goToRoute (route) {
      this.$router.push(route).catch(() => {})
      this.$store.commit('storePanelViewedOnMobile', 'Left')
      this.$emit('show')
    },
    logout () {
      this.$store.commit('storeUserData', { aut: false, lbl: [] })
      this.$store.commit('storeId', 0)
      this.$store.commit('storeHero', 0)
      this.$store.commit('storeSport', 0)
      this.$store.commit('storeColour', '#e1e1e1')
      this.$store.commit('storeNumber', 0)
      this.$store.commit('storeFirstInitial', '')
      this.$store.commit('storeSecondInitial', '')
      this.$store.commit('storeClubUrl', '')
      this.$store.commit('clearBasket')
      this.$store.commit('storeLoginData', true)
      this.$router.push({ path: '/play', query: { page: 1 } }).catch(() => {})
      this.$emit('logout')
      this.$emit('show')
    }
  }
}
</script>

<style lang="scss">
.navbarMenuItem {
  font-size: 1.2rem;
  border-radius: 10px;
  margin: 0 5px;
  padding: 5px 10px;
  color: var(--pr-color);
}
.navbarMenuItemAlt {
  font-size: 1.2rem;
  border-radius: 10px;
  margin: 0 5px;
  padding: 5px 10px;
  color: var(--pr-color);
}
.navbarMenuItem:hover {
  scale: 1.02;
  transition: .05s;
  color: var(--lt-color);
}
.navbarMenuItem.selected {
  background: var(--pr-color);
  color: var(--bg-color);
}
.mdbvue-sidenav__content {
  background: var(--bg-color);
}
.footer-circle {
  cursor:pointer;
  border: solid 2px white;
  border-radius:30px;
  width:50px;
  height: 50px;
  text-align: center;
  align-content: center;
  float: left;
}
</style>
