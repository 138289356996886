// LOCAL
// export const API_URL = 'http://localhost:36524'
// export const WEB_URL = 'http://localhost:8080'
// export const LIVE_STATUS = 0

// UAT
// export const API_URL = 'https://grassroots-lottery-api.azurewebsites.net'
// export const WEB_URL = 'https://uat.grassrootslottery.co.uk'
// export const LIVE_STATUS = 0

// LIVE
export const API_URL = 'https://api.grassrootslottery.co.uk'
export const WEB_URL = 'https://play.grassrootslottery.co.uk'
export const LIVE_STATUS = 1

// Testing GUID
export const GUID = 'safc17021967ftm1990jty638x'
